.about-us-container {
    padding: .5em 3em;
    max-width: 90%;
    margin: 0 auto 4em auto;
    font-size: 1.2rem;
    line-height: 2rem;
    position: relative;
}

.about-us-container span {
    color: var(--primary);
    font-weight: bold;
}


@media screen and (max-width:750px) {
    .about-us-container {
        padding: .5em 0;
        max-width: 100%;
        font-size: 1em;
        line-height: 1.5em;
    }
}