.hero-wrapper {
    background-color: var(--surface-45);
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

@media screen and (max-width: 830px) {
    .hero-wrapper {
        grid-template-columns: 1fr;
    }
}

.hero-text {
    grid-column: 2;
    max-width: 600px;
    line-height: normal;
    color: var(--on-surface);
    text-transform: uppercase;
    font-size: 2.5em;
    justify-self: flex-start;
    align-self: flex-end;
    margin: 2em 0;
}

@media screen and (max-width: 830px) {
    .hero-text {
        text-align: center;
        font-size: 2em;
        margin: 2em auto;
    }
}


.hero-catch-phrase {
    grid-column: 2;
    grid-row: 2;
    justify-self: flex-start;
    align-self: flex-start;
    margin-left: 5em;
}

@media screen and (max-width: 830px) {
    .hero-catch-phrase {
        margin: 0 auto;
    }
}

.place-bottom {
    position: absolute;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5em;
}