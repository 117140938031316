:root {
  /* settings */
  --header-height: 5em;
  --footer-height: 18em;
  --footer-separator-size: 2px;
  --descriptive-product-height: 20em;

  /* colors */
  --primary: rgba(255, 24, 33, 1);
  --secondary: rgba(49, 57, 58, 1);
  --background: rgba(255, 255, 255, 1);
  --surface: rgba(0, 0, 0, 1);
  --surface-85: rgba(0, 0, 0, 0.85);
  --surface-65: rgba(0, 0, 0, 0.65);
  --surface-55: rgba(0, 0, 0, 0.55);
  --surface-45: rgba(0, 0, 0, 0.45);
  --surface-35: rgba(0, 0, 0, 0.35);
  --surface-25: rgba(0, 0, 0, 0.25);
  --on-surface: rgba(255, 255, 255, 1);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  border: none;
}

body {
  margin: 0;
  z-index: 0;
  font-family: nova, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-default {
  cursor: default;
}

main {
  --total-taken-screen-size:
    calc(var(--header-height) + var(--footer-height) + var(--footer-separator-size));

  display: block;
  min-height: calc(100vh - var(--total-taken-screen-size));
}


.page-container {
  width: calc(85% - 2em);
  max-width: 1980px;
  padding: 1em;
  margin: 3em auto;
}

.page-container-margin-bottom {
  margin-bottom: 12em;
}

.page-title {
  margin: .5em auto;
  display: block;
  width: 100%;
  font-size: 3rem;
  text-align: center;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  text-transform: uppercase;
}

@media screen and (max-width:800px) and (min-width: 650px) {
  .page-title {
    font-size: 2.5em;
  }
}

@media screen and (max-width:649px) {
  .page-title {
    font-size: 2em;
  }
}


.section-title {
  margin-bottom: 1em;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  outline: none;
}

@media screen and (max-width:375px) {

  .section-title {
    font-size: 1.5rem;
  }
}


.hero-container {
  height: 50vh;
  padding-top: var(--header-height);
  position: relative;
}


@keyframes show-with-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.hero {
  background-repeat: no-repeat;
  background-position: 0;
  background-size: cover;
}

.home-hero {
  background-image: url('./assets/images/hero-banner-1.jpeg');
}