.site-header {
    background-color: var(--surface-85);
    height: var(--header-height);
    display: grid;
    position: absolute;
    grid-template-columns: 25vw 1fr;
    top: 0;
    width: 100%;
    z-index: 9;
}

.site-header a {
    display: block;
    max-height: calc(var(--header-height) - 1em);
    margin: .5em .5em .5em 1em;
}

.site-header a img {
    height: 100%;
}

.menu-container {
    height: var(--header-height);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1em;
}