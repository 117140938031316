.success-stories-wrapper {
    width: 85vw;
    height: 12em;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

@media screen and (max-width: 900px) {
    .success-stories-wrapper {
        width: 75vw;
        height: 33em;
        overflow: visible;
    }
}

@media screen and (min-width: 901px) and (max-width: 1185px) {
    .success-stories-wrapper {
        width: 85vw;
        height: 33em;
        overflow: visible;
    }
}

@media screen and (min-width:1600px) and (max-width:1899px) {
    .success-stories-wrapper {
        width: 65vw;
    }
}

@media screen and (min-width:1900px) and (max-width:2239px) {
    .success-stories-wrapper {
        width: 60vw;
    }
}

@media screen and (min-width:2240px) {
    .success-stories-wrapper {
        width: 50vw;
    }
}

.stories-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.stories-row.show {
    display: flex;
    animation: show-story-row .4s linear forwards;
}

.stories-row.hide {
    display: none;
    opacity: 0;
    animation: hide-story-row .4s linear forwards;
}

.story {
    display: flex;
    align-items: center;
    width: 15em;
    height: 10em;
    margin: .5em 3em;
}

.story img {
    width: 100%;
    max-height: 100%;
}


@keyframes show-story-row {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes hide-story-row {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}