.coming-soon {
    margin: 15% auto;
    vertical-align: middle;
    text-align: center;

}

.coming-soon label {
    font-weight: 700;
    font-size: 3rem;
    color: var(--primary);
}