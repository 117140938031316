.general-info {
    --product-image-size: 20em;

    margin: 5em auto;
    display: grid;
    grid-template-columns: var(--product-image-size) 1fr;
    column-gap: 2em;
    justify-content: flex-start;
}


@media screen and (min-width:1200px) {
    .general-info {
        max-width: 75%;
    }
}

@media screen and (max-width:750px) and (min-width: 650px) {
    .general-info {
        --product-image-size: 15em;
    }
}

@media screen and (max-width:649px) {
    .general-info {
        --product-image-size: 10em;

        grid-template-columns: 1fr;
        grid-template-rows: var(--product-image-size) 1fr;
    }
}

.general-info img {
    width: var(--product-image-size);
    height: var(--product-image-size);
    margin: 0 auto;
    object-fit: contain;
}

.general-info p {
    font-size: 1.5em;
    line-height: 1.5em;
    padding: 1em;
}


.product-details-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-details-item {
    margin: 1em 0 1.5em 0;
}


.return-link {
    color: var(--primary);
}