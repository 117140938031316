.site-footer {
    background: var(--surface);
    height: var(--footer-height);
    border-top: var(--footer-separator-size) solid var(--primary);
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 30% 1fr .7fr;
}

@media screen and (max-width:1000px) {
    .site-footer {
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr .5fr;
    }
}

.site-footer>img {
    --logo-padding: 1em;

    display: block;
    margin: 0 auto;
    padding: 0.5em;
    height: calc(var(--footer-height) * 0.85 - var(--logo-padding) * 2);
}

.contact-info-container {
    --contact-padding: 0.5em;

    height: calc(var(--footer-height) - var(--contact-padding) * 2);
    display: flex;
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width:1000px) {
    .contact-info-container {
        height: auto;
    }
}

.contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.25em;
    padding: var(--contact-padding);
}

.contact.phone {
    display: grid;
    grid-template-columns: 1.5em 1fr;
    column-gap: .5em;
    align-items: flex-start;
}

.contact .icon-container {
    width: 1.2em;
    height: 1.2em;
    padding: 0.5em;
    border-radius: 0.5em;
    background-color: var(--primary);
    transition: background-color 0.2s ease-in-out;
}

.icon-container svg {
    width: 100%;
    height: 100%;
    fill: var(--background);
}

.contact a {
    margin: 0 0.5em;
    font-size: 1.25rem;
    max-width: calc(100% - 3.5em);
    text-decoration: underline;
    text-decoration-thickness: .02em;
    text-underline-offset: 0.2em;
    color: var(--on-surface);
    transition: color 0.2s ease-in-out;
}

.contact.phone a {
    margin: 0 .5em .5em .5em;
    ;
}

.contact a:hover {
    color: var(--primary);
}

.contact a:hover+.icon-container svg {
    fill: var(--secondary);
}

.contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.social-media-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5em auto;
}

.media {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    margin: 0.5em 1em;
    padding: 0.5em;
    border-radius: 1em;
    background-color: var(--primary);
    transition: background-color 0.2s ease-in-out;
}

.media a {
    display: block;
    margin: .3em 0 0 0;
}

.media a svg {
    width: 2.2em;
    height: 2.2em;
    fill: var(--on-surface);
}

.media:hover {
    background-color: var(--background);
}

.media:hover a svg {
    fill: var(--secondary);
}


.copyright-container {
    border-top: var(--footer-separator-size) solid var(--primary);
    height: 3em;
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.copyright-container span {
    color: var(--primary);
    display: inline-block;
    margin: 0 .3em;
}

@media screen and (max-width:600px) and (min-width:321px) {
    .copyright-container {
        font-size: .7rem;
    }
}

@media screen and (max-width:320px) {
    .copyright-container {
        font-size: .5rem;
    }
}