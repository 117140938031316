.not-found-container {
    margin: 15% auto;
    vertical-align: middle;
    text-align: center;

}

.not-found-container label {
    font-weight: 700;
    font-size: 3rem;
    color: var(--primary);
}