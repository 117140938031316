.descriptive-product {
    display: block;
    max-width: 100%;
    height: var(--descriptive-product-height);
    margin-bottom: 8em;
}

h2.product-title {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    margin-bottom: .5em;
    text-transform: capitalize;
}

.divider {
    display: grid;
    grid-template-columns: .7fr .9fr;
    column-gap: 1em;
    align-items: center;
}

.divider img {
    width: 100%;
    height: var(--descriptive-product-height);
    object-fit: contain;
}

.divider p {
    font-size: 1.2rem;
    white-space: pre-wrap;
}


@media screen and (max-width: 1199px) {
    .descriptive-product {
        height: auto;
    }

    .divider {
        grid-template-columns: 1fr;
        grid-template-rows: .3fr .5fr;
        column-gap: 0;
    }

    .divider img {
        height: calc(var(--descriptive-product-height) / 1.2);
    }
}


@media screen and (min-width:1920px) {
    .divider {
        grid-template-columns: .5fr .7fr;
    }

    .divider p {
        font-size: 1.5rem;
        line-height: 1.5em;
    }
}