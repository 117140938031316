.navigation-tree-container {
    display: flex;
    column-gap: .1em;
    align-items: flex-start;
    justify-content: flex-start;
    padding: .5em 0;
}

.navigation-links-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.navigation-links-wrapper>.navigation-link {
    margin: 0 .8em;
    font-size: 1.1rem;
    position: relative;
    color: var(--primary);
    text-transform: capitalize;
}

.navigation-links-wrapper>.navigation-link::after {
    content: "/";
    position: absolute;
    margin-left: .5em;
    color: var(--secondary);
}


.navigation-links-wrapper>.navigation-link:last-child:after {
    display: none;
}


.unlinked {
    color: var(--secondary) !important;
}