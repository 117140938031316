.products-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    row-gap: 5em;
    margin: 4em auto 0 auto;
}

@media screen and (max-width: 1200px) {
    .products-wrapper {
        justify-content: center;
    }
}

.products-wrapper .product {
    width: 30%;
}

@media screen and (max-width: 1200px) {
    .products-wrapper .product {
        width: 100%;
    }
}