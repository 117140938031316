.details-section-data-table {
    width: 100%;
    display: table;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    margin: 1em 0;
}

.details-section-data-row {
    display: table-row;
    width: 100%;
}

.details-section-data-header {
    display: table-cell;
    width: 25%;
    font-weight: bold;
    padding: .5em;
    background-color: var(--surface-25);
    color: var(--secondary);
    border-bottom: 1px solid var(--secondary);
    text-transform: capitalize;
}

.details-section-data-content {
    display: table-cell;
    width: 75%;
    padding: .5em;
    border-left: 1px solid var(--secondary);
    border-bottom: 1px solid var(--secondary);
}

.details-section-data-content-item,
.details-section-data-content-list {
    padding-left: .6em;
    margin: .5em 0;
}

.details-section-data-content-list::before {
    content: "•";
    font-size: 1.5em;
    margin-right: .2em;
    margin-left: -.5em;
    vertical-align: text-bottom;
}

.details-section-data-row:last-of-type .details-section-data-header,
.details-section-data-row:last-of-type .details-section-data-content {
    border-bottom: none;
}