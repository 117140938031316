h1 span {
    color: var(--primary);
}

@media screen and (max-width: 950px) {
    .home-hero {
        background-position: -5em;
    }
}

.page-container section {
    margin-bottom: 5em;
}

.categories-container {
    display: flex;
    align-items: center;
    margin-top: -5em;
    padding: 1.5em 0;
    background-image: url('./assets/images/barcode-bg.jpg');
}


.categories-wrapper {
    width: 100%;
    max-width: 1980px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.category {
    --category-width: 18%;

    width: var(--category-width);
    margin: 1em calc(calc(20% - var(--category-width)) / 2);
}

.category .category-inner {
    background-color: var(--background);
    border-radius: 1em;
}

@media screen and (max-width: 1600px) and (min-width:1251px) {
    .category {
        --category-width: 20%;
        margin: 1em;
    }
}

@media screen and (min-width: 901px) and (max-width: 1250px) {
    .category {
        --category-width: 30%;
        margin: 1em;
    }
}

@media screen and (min-width: 676px) and (max-width: 900px) {
    .category {
        --category-width: 35%;
        margin: 1em;
    }
}

@media screen and (min-width: 601px) and (max-width: 675px) {
    .category {
        --category-width: 40%;
        margin: 1em;
    }
}

@media screen and (min-width: 426px) and (max-width: 600px) {
    .category {
        --category-width: 80%;
        margin: 1em;
    }
}

@media screen and (max-width: 425px) {
    .category {
        --category-width: 100%;
        margin: 1em;
    }

    .category-inner {
        width: 80%;
        margin: 0 auto;
    }
}


.final-section {
    margin: 10em auto 0 auto !important;
}