.menu-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    font-size: 1.2rem;
}

.menu-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    transition: opacity .2s ease-in-out;
}

.menu-links.show {
    display: flex;
    opacity: 1;
}

.menu-toggle {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 1em;
    width: 5em;
    height: 5em;
    padding: 1em;
    cursor: pointer;
    display: none;
}

.menu-toggle svg {
    width: 100%;
    height: 100%;
    fill: var(--on-surface);
    transition: fill .2s ease-in-out;
}

.menu-item {
    position: relative;
    height: 100%;
}

.menu-item svg {
    display: none;
}

.menu-item a {
    background: transparent;
    border: none;
    font-weight: 300;
    text-decoration: none;
    color: var(--on-surface);
}

.menu-item .main-link {
    --border-size: 3px;

    display: flex;
    align-items: center;
    height: calc(100% - var(--border-size));
    padding: 0 .5em;
    margin: 0 .2em;
    border-bottom: var(--border-size) solid transparent;
    transition: border-color .2s ease-in-out;
}

.main-link.active,
.main-link:hover {
    border-color: var(--primary);
}

.sub-menu-container {
    --sub-menu-width: 10em;

    position: absolute;
    display: none;
    opacity: 0;
    width: var(--sub-menu-width);
    padding: .5em 0;
    left: calc(50% - var(--sub-menu-width) * .35);
    top: calc(var(--header-height) * 1.05);
    font-size: initial;
    background-color: var(--surface-85);
    transition: opacity .2s .2s linear;
    z-index: 999;
}

.sub-menu-container.show {
    display: block;
    opacity: 1;
}

.sub-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sub-menu-item a {
    width: 100%;
    margin: 0;
    padding: .7em .5em .7em 1em;
    transition: background-color .1s ease-in-out;
}

.sub-menu-item a:hover {
    background-color: var(--secondary);
    color: var(--primary);
}




@media screen and (max-width:800px) {
    .menu-links {
        display: none;
        opacity: 0;
    }

    .menu-toggle {
        display: block;
    }

    .menu-links {
        position: absolute;
        height: auto;
        width: 10em;
        right: .3em;
        background-color: var(--surface-85);
        top: calc(var(--header-height) * .9);
        flex-direction: column;
    }

    .sub-menu-container {
        top: 0;
        left: calc(-5% - var(--sub-menu-width));
    }

    .menu-item {
        margin: .6em auto;
        height: auto;
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 1em 1fr;
    }

    .menu-item a {
        grid-column: 2;
    }

    .menu-item svg {
        display: block;
        fill: var(--on-surface);
        margin: 0 auto;
        margin-top: 1px;
    }

    .main-link {
        border: none !important;
    }

    .main-link.active,
    .main-link:hover {
        color: var(--primary);
    }
}