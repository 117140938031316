.labels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
}

.label-type {
    --grid-horizontal-margin: 2em;

    max-width: calc(50% - calc(var(--grid-horizontal-margin) * 2));
    width: calc(50% - calc(var(--grid-horizontal-margin) * 2));
    display: grid;
    grid-template-rows: .75fr .25fr;
    margin: 3em var(--grid-horizontal-margin);
}

@media screen and (max-width: 1200px) {
    .label-type {
        max-width: 100%;
        width: 100%;
    }
}

.label-type:last-of-type {
    margin-bottom: 5em;
}

.label-type img {
    --image-size: 20em;

    width: var(--image-size);
    height: var(--image-size);
    object-fit: contain;
    margin: auto;
}

.label-type h2 {
    text-align: center;
    font-size: 2rem;
    margin: 2em 0;
}


.labels-hero {
    background-image: url('../../assets/images/hero-banner-2.jpg');
    background-size: 100% 100%;
}

.labels-page-content {
    margin-top: 10em;
}