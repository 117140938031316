@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-100.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 100;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-200.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 200;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-300.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-400.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-500.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-600.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-700.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-800.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 800;
    font-stretch: normal;
}

@font-face {
    font-family: "nova";
    src: url("./assets/fonts/nova-900.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
}