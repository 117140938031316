.our-mission {
    padding: .5em 3em;
    width: fit-content;
    max-width: 90%;
    margin: 0 auto 4em auto;
    font-size: 1.2rem;
    line-height: 2rem;
    position: relative;
}


.our-mission span {
    color: var(--primary);
}


@media screen and (max-width:750px) {
    .our-mission {
        padding: .5em 0;
        max-width: 100%;
        font-size: 1em;
        line-height: 1.5em;
    }
}