.category {
    --category-height: 20em;

    position: relative;
    min-height: var(--category-height);
    cursor: default;
}

.category-inner {
    min-height: var(--category-height);
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.category-front {
    display: grid;
    height: var(--category-height);
    grid-template-rows: 1fr .5fr;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.category-front img {
    width: 80%;
    height: calc(var(--category-height) * .75);
    margin: .5em auto;
    object-fit: contain;
}

.category-front h2 {
    grid-row: 2;
    font-size: 1.3rem;
    padding: 0 .5em;
    text-transform: uppercase;
    text-align: center;
    align-self: flex-end;
}

.category-back {
    display: none;
    opacity: 0;
    height: calc(var(--category-height) * .95 - 1em);
    flex-direction: column;
    padding: 1em;
    transform: rotateY(180deg);

}

.category-back .category-description {
    margin: .5em auto;
    height: calc(18em - 5em);
    overflow: auto;
}

.category-back a {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: .5em;
    right: .7em;
    color: var(--primary);
    fill: var(--primary);
    font-size: 1.2rem;
    text-decoration: none;
}

.category-back a svg {
    margin-left: .5em;
}

.category:hover .category-front {
    display: none;
}

.category:hover .category-back {
    display: flex;
    animation: show-with-opacity 0.2s .2s forwards;
}

.category:hover .category-back .category-description {
    transform: rotateY(0);
}

.category:hover .category-inner {
    transform: rotateY(180deg);
}