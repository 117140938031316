.product {
    display: grid;
    align-items: flex-start;
    grid-template-rows: 11em 1fr 2em;
    /* margin: 0 auto; */
    margin: 0 1em;
    position: relative;
}

.product img {
    max-width: 12em;
    height: 10em;
    margin: 0 auto;
}

.product-details {
    display: flex;
    flex-direction: column;
}

.product-details h2 {
    text-transform: capitalize;
    font-size: 1.6rem;
    height: 1.5em;
    margin: .5em auto .3em auto;
    text-align: center;
}

@media screen and (max-width: 1200px) and (min-width: 950px) {
    .product-details h2 {
        height: 3em;
    }
}

.product-details p {
    font-size: 1.2rem;
    max-width: 85%;
    margin: 1.2em auto 1em auto;
}

@media screen and (max-width: 950px) {
    .product-details p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.product .read-more {
    text-decoration: none;
    grid-row: 3;
    background-color: var(--secondary);
    color: var(--on-surface);
    margin: 0 auto;
    padding: .5em;
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color .2s ease-in-out;
}

.product .read-more:hover {
    background-color: var(--primary);
    border-color: var(--secondary);
}


.featured-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5em;
    height: 1.5em;
    display: none;
}

@media screen and (max-width: 950px) and (min-width:851px) {
    .featured-icon {
        right: 25%;
    }
}

@media screen and (max-width: 850px) and (min-width:701px) {
    .featured-icon {
        right: 20%;
    }
}

@media screen and (max-width: 700px) and (min-width:501px) {
    .featured-icon {
        right: 15%;
    }
}

@media screen and (max-width: 500px) and (min-width:375px) {
    .featured-icon {
        right: 10%;
    }
}

.featured-icon.show {
    display: block;
}

.featured-icon svg {
    width: 100%;
    height: 100%;
    fill: var(--primary);
}